import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
// import Header from "../../components/KenHeader";
import FormInfo from "./components/InfoForm";
import OtpVerify from "./components/OtpVerify";
import ThankYou from "./components/ThankYou";
const LandingPage =
  "https://inazstgpfs3001.blob.core.windows.net/assets/Images/SAMSUNG/ESSCISamsung.png";

const useStyles = makeStyles((theme) => ({
  root: {
    //boxShadow: `0px 0px 9px ${theme.palette.KenColors.shadowColor}`,
    //borderRadius: 3,
    // width: "100%",
    Width: 300,
    borderRadius: "10px",
  },
  boxRoot: {
    maxWidth: 550,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.KenColors.neutral40}`,
    padding: 16,
    position: "relative",
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.KenColors.primary,
    width: "100%",
  },
  title: {
    textTransform: "uppercase",
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "bold",
    flex: 3,
    backgroundColor: theme.palette.KenColors.primary,
    padding: 8,
  },
  headerTitle: {
    textTransform: "uppercase",
    color: theme.palette.KenColors.neutral700,
    fontSize: 18,
    fontWeight: "bold",
    flex: 3,
    padding: 8,
    marginBottom: -5,
  },
  help: {
    height: 21,
  },
  // logo: {
  //   height: 50,
  //   position: "absolute",
  //   left: 16,
  // },
  boxBackground: {
    // backgroundSize: '100% 100vh',
    backgroundSize: "cover",
    backgroundColor: "#fff",
    backgroundImage: `url(${LandingPage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
  },
  formBox: {
    padding: "7px",
    marginTop: 5,
    paddingRight: "6%",
  },
}));
export default function Form(props) {
  const [showForm, setShowForm] = useState(true);
  const [showTanks, setShowTanks] = useState(true);
  const [emailId, setEmailId] = useState(true);
  const [horizontalLayout, setHorizontalLayout] = useState(false);
  const [leadData, setLeadData] = useState(null);
  const [contactData, setContactData] = useState(null);

  const classes = useStyles();

  React.useEffect(() => {
    const layout = new URLSearchParams(props.location.search).get("layout");
    console.log("==== ", layout === "horizontal");
    setHorizontalLayout(layout === "horizontal");
  }, []);

  return (
    <Box
      style={{ justifyContent: "center", display: "flex", height: "100%" }}
      className={classes.boxBackground}
    >
      {/* <Header /> */}
      <Grid
        container
        spacing={0}
        align="center"
        alignItems="flex-end"
        justify="center"
        direction="column"
        style={{
          minHeight: "100vh",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <Grid item className={classes.formBox}>
          <Box
            className={
              horizontalLayout === false ? classes.boxRoot : classes.boxVertical
            }
          >
            <Paper
              className={
                horizontalLayout === false ? classes.root : classes.vertical
              }
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                className={classes.formRow}
              >
                {showForm ? (
                  <FormInfo
                    setShowForm={setShowForm}
                    emailId={emailId}
                    setEmailId={setEmailId}
                    horizontalLayout={horizontalLayout}
                    setLeadData={setLeadData}
                    setContactData={setContactData}
                  />
                ) : showTanks ? (
                  <OtpVerify
                    showTanks={showTanks}
                    setShowTanks={setShowTanks}
                    setShowForm={setShowForm}
                    emailId={emailId}
                    setEmailId={setEmailId}
                    leadData={leadData}
                    contactData={contactData}
                  />
                ) : (
                  <>
                    <Box className={classes.header}>
                      <Box item className={classes.title}>
                        Thank You
                      </Box>
                    </Box>
                    <ThankYou></ThankYou>
                  </>
                )}
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
