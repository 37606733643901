import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import KenButton from "../../../components/KenButton";
import KenInputField from "../../../components/KenInputField";
import { useTranslation } from "react-i18next";
import {
  createContact,
  postDetails,
  sendOtp,
  validateOtp,
} from "../../../utils/ApiService";
import KenSnackBar from "../../../components/KenSnackbar";
import KenLoader from "../../../components/KenLoader/KenLoader";

export default function OtpVerify(props) {
  const {
    showTanks,
    setShowTanks,
    emailId,
    leadData,
    contactData,
    setShowForm,
  } = props;
  let [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const [state, setState] = React.useState({
    seconds: 0,
    minutes: 2,
  });
  const [otp, setOtp] = React.useState(null);

  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
  };
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const handleSnackbarOpen = (severity, message) => {
    setOpenSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  React.useEffect(() => {
    const myInterval = setInterval(() => {
      const { seconds, minutes } = state;
      if (seconds > 0) {
        setState({ ...state, seconds: seconds - 1 });
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setState({ ...state, minutes: minutes - 1, seconds: 59 });
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [state]);

  const LeadContact = () => {
    setLoading(true);
    postDetails(leadData)
      .then((res) => {
        setLoading(false);

        if (res?.success) {
          setShowTanks(false);

          handleSnackbarOpen("success", "Registration Successful");
        } else {
          handleSnackbarOpen("warning", "Something went wrong.");
        }
      })
      .catch((error) => {
        if (error) {
          handleSnackbarOpen(
            "error",
            error?.response?.data?.[0]?.message || "Something went wrong"
            // "This email is already exist" || "Something went wrong"
          );
          setTimeout(() => {
            setShowForm(true);
          }, 5000);
        }

        setLoading(false);
      });
  };

  const ResendOtp = () => {
    sendOtp(emailId)
      .then((resOtp) => {
        if (resOtp?.success === true) {
          setLoading(false);
          handleSnackbarOpen("success", "OTP sent to email");
        }
      })
      .catch((err) => {
        console.log("OTP send Error", err);
      });
    setState({
      seconds: 0,
      minutes: 2,
    });
  };
  const Verify = () => {
    setLoading(true);
    validateOtp(emailId, otp)
      .then((res) => {
        setLoading(false);
        console.log("response", res);
        if (res.success === true) {
          // handleSnackbarOpen("success", t("OTP verified successfully"));
          LeadContact();
        } else {
          handleSnackbarOpen("error", res?.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Err in validation", err);
        handleSnackbarOpen("error", "Something went wrong Try Again");
      });
  };

  return (
    <Paper elevation={2} style={{ padding: "20%" }}>
      {loading && <KenLoader size={24} />}

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid>
          <Typography align="center" variant="h5">
            Verify Your Email ID
          </Typography>
          <Typography>
            Please enter the OTP sent to your registered Email ID.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <KenInputField
              placeholder={
                "Please enter the OTP sent to your registered Email ID."
              }
              required
              label="OTP"
              name="otp"
              value={otp}
              onChange={handleChangeOtp}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <KenButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            //   className={classes.submit}
            onClick={Verify}
          >
            Verify OTP
          </KenButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              {state?.minutes === 0 && state?.seconds === 0 ? (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <KenButton onClick={ResendOtp}>Resend OTP</KenButton>
                </Box>
              ) : (
                <Typography align="center">
                  {state?.minutes}:
                  {state?.seconds < 10 ? `0${state?.seconds}` : state?.seconds}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <KenSnackBar
          message={snackbarMessage}
          severity={snackbarSeverity}
          autoHideDuration={5000}
          open={openSnackbar}
          handleSnackbarClose={handleSnackbarClose}
          position="Bottom-Right"
        />
      </Grid>
    </Paper>
  );
}
