import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography, Grid } from "@material-ui/core";

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: "#B3BAC5",
    "&$checked": {
      color: theme.palette.KenColors.primary,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default function KenCheckbox(props) {
  const { disabled, name, label, value, onChange } = props;

  return (
    <FormGroup disabled>
      <Grid container alignItems="center">
        <FormControlLabel
          control={
            <GreenCheckbox
              // color="yellow"
              disabled={disabled}
              checked={value}
              onChange={onChange}
              name={name}
              Component-root={{ style: { color: "pink" } }}
              // className={disabled ? classes.root : classes.root}
              style={
                disabled
                  ? { color: "#DFE1E6", marginRight: "-20px" }
                  : { color: "", marginRight: "-20px" }
              }
            />
          }
          // label="Custom color"
        />
        <Typography> {label} </Typography>
      </Grid>
    </FormGroup>
  );
}
