import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import KenColors from "../../../themes/KenColors";

const useStyles = makeStyles({
  text: {
    textAlign: "start",
    fontSize: 13,
    fontWeight: "700",
  },
  contain: {
    textAlign: "start",
    fontSize: 13,
    fontWeight: "700",
    width: "82%",
    color: "#000",
    textAlign: "center",
  },
  root: {
    padding: "16px 12px 16px",
    backgroundColor: "transparent",
    borderRadius: 12,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
});
export default function ThankYou() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.contain}>
        {/* Thanks for registering. */}
        {/* Thank you, you have successfully submitted your enquiry. */}
        Thank you for your enquiry. You will receive further instructions via your registered email.
      </Typography>
    </Box>
  );
}
