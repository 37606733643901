import axios from "axios";

let applicationConfig;
let paymentInstance;

const tokenUrl =
  "https://ltpct-stg-applicationportal.ken42.com/api/app/azuretoken";

const axiosInstance = axios.create({
  baseURL: "https://api-v2.ken42.com/ltpctstageapp",
  timeout: 15000,
});

const getInsConfig = (config) => {
  return config;
};

const getAxiosInstance = async (data) => {
  applicationConfig = await getInsConfig(data);
  axiosInstance = await axios.create({
    baseURL: `${applicationConfig.apiURL}`,
  });
  // Request interceptor for API calls
  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem("access_token");
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      // console.log(resp);
      return resp;
    },
    async function (error) {
      //console.log(error.response.status);
      var config = {
        method: "get",
        baseURL: applicationConfig.tokenURL, //'https://api-dev.ken42.com:8243/token?grant_type=client_credentials',
        // headers: {
        //   Authorization: `Basic ${applicationConfig.tokenAuth}`,
        // },
      };
      return axios(config).then(function (response) {
        // console.log(JSON.stringify(response.data.access_token));
        localStorage.setItem("access_token", response.data.access_token);
        error.response.config.headers[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`;
        return axios(error.response.config);
      });
    }
  );
  return axiosInstance;
};

axiosInstance.interceptors.request.use((req) => {
  const token = sessionStorage.getItem("access_token");
  req.headers.Authorization = `Bearer ${token}`;
  return req;
});

//Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  async function (error) {
    console.log(error.response.status);
    var config = {
      method: "get",
      baseURL: tokenUrl,
    };
    return axios(config).then(function (response) {
      console.log(JSON.stringify(response.data.access_token));
      sessionStorage.setItem("access_token", response.data.access_token);
      error.response.config.headers[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;
      return axios(error.response.config);
    });
  }
);
const createContact = async (data) => {
  // const res = await axiosInstance.post("https://api-v2.ken42.com/ltpctstageapp/create-contact", data);
  const res = await axiosInstance.post(
    "https://api-v2.ken42.com/essci-samsung/prod/api/sf/data?api=services/data/v54.0/sobjects/Contact",
    data
  );
  return res && res.data ? res.data : null;
};
const getYear = async () => {
  const path = `/batchyear`;
  const res = await axiosInstance.get(path);
  return res && res.data ? res.data : null;
};

const getDepartment = async () => {
  const path = `/department`;
  const res = await axiosInstance.get(path);
  return res && res.data ? res.data : null;
};

const getPrograms = async (value) => {
  const path = `/program`;
  const res = await axiosInstance.get(path);
  return res && res.data ? res.data : null;
};

// const postDetails = async (data) => {
//   const path = `/lead`;
//   const res = await axiosInstance.post(path, data);
//   return res && res.data ? res.data : null;
// };

const postDetails = async (data) => {
  const path = `https://api-v2.ken42.com/essci-samsung/prod/api/salesforce?endpoint=/services/data/v54.0/sobjects/Lead`;
  const res = await axiosInstance.post(path, data, {
    headers: {
      'Sforce-Auto-Assign': true,
    },
  });
  return res && res.data ? res.data : null;
};
const sendOtp = async (queryParams) => {
  const path = `https://api-v2.ken42.com/essci-samsung/prod/api/salesforce?endpoint=/services/apexrest/OneTimePassword/send&email=${queryParams}`;
  const res = await axiosInstance.post(path);
  return res && res.data ? res.data : null;
};
const validateOtp = async (email,otp) => {
  const path = `https://api-v2.ken42.com/essci-samsung/prod/api/salesforce?endpoint=/services/apexrest/OneTimePassword/verify&contact=${email}&otpEnteredEmail=${otp}`;
  const res = await axiosInstance.get(path);
  return res && res.data ? res.data : null;
};

export {
  getYear,
  getDepartment,
  postDetails,
  getPrograms,
  createContact,
  sendOtp,
  validateOtp,
};
