import React, { useState, useEffect, createContext } from "react";
import { withFormik } from "formik";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import * as Yup from "yup";
import KenButton from "../../../components/KenButton";
import KenLoader from "../../../components/KenLoader/KenLoader";
import KenSnackBar from "../../../components/KenSnackbar";
import KenInputField from "../../../components/KenInputField";
import KenSelect from "../../../components/KenSelect";
import configContext from "../../../utils/config";
import { GLOBAL } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { otpGenerator } from "../../../utils/Otp";
import clsx from "clsx";
import {
  getYear,
  postDetails,
  getPrograms,
  getDepartment,
  createContact,
  sendOtp,
} from "../../../utils/ApiService";
import {
  sendOtpTo,
  isOtpValid,
  isUserAlreadyRegistered,
  getContactId,
  storeInfo,
} from "../../helper";
import KenCheckbox from "../../../components/KenCheckbox/index";
import CountryStateJson from "../../../utils/CountryStates.json";
import StateCityJson from "../../../utils/StateCity.json";
import CountryJson from "../../../utils/studentInfoCountry.json";
import Messages from "../../../json/validationErrors.json";
import countryCodes from "../../../utils/countryISDCode.json";

const messages = Messages.messages;
const useStyles = makeStyles({
  selectGrid: {
    flexGrow: 0,
    // maxWidth: "100%",
    flexBasis: "100%",
    overflowX: "hidden",
    width: 220,
  },
  declarationText: {
    fontSize: "14px",
    paddingTop: "10px",
    textAlign: "justify",
  },
  submitBtn: {
    marginBottom: 10,
    fontSize: 14,
  },
  hide: {
    display: "none",
  },
  headerTitle: {
    textTransform: "uppercase",
    // color: '#fff',
    fontSize: 18,
    fontWeight: "bold",
    flex: 3,
    padding: 8,
    // marginBottom: -5,
  },
});

export default function FormInfo(props) {
  return (
    <div>
      <FormikHoc {...props} />
    </div>
  );
}

const InfoForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    loading,
    prog,
    setProg,
    setLoading,
    academicPrograms,
  } = props;
  const classes = useStyles();
  const options = countryCodes.codes;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    if (name === "mobile") {
      setMobile(e.target.value);
    }

    if (name === "country") {
      setFieldValue("state", "");
    }

    if (name === "state") {
      setFieldValue("city", "");
    }
    setFieldTouched(name, true, false);
  };
  const { t } = useTranslation();
  const [year, setYear] = useState([]);
  const [department, setDepartment] = useState([]);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = React.useState();
  const [showOtpEnter, setShowOtpEnter] = React.useState(false);
  const [changeSize, setChangeSize] = React.useState(4);
  const [changeBtnSize, setChangeBtnSize] = React.useState(2);
  const [courseOptions, setCourseOptions] = useState();
  const getCities = (value) => {
    // console.log("state for city", value);
    const cityStates = StateCityJson.states.find(
      (state) => state.value === value
    );
    let districts = cityStates?.districts || [];
    let cities = [];
    districts.forEach((district) => {
      cities.push({
        label: district,
        value: district,
      });
    });
    // console.log("cities", cities);
    return cities;
  };
  // const AllcourseOptions = [
  //   { label: "AI", value: "0019D00000P5aVhQAJ" },
  //   { label: "Big Data", value: "0019D00000P5aW1QAJ" },
  //   { label: "IoT", value: "0019D00000P5aWqQAJ" },
  //   { label: "C and P", value: "0019D00000P5aWkQAJ" },
  // ];

  const educationOption = [
    { label: "12th", value: "12th" },
    { label: "UG", value: "UG" },
    { label: "PG", value: "PG" },
    { label: "Other", value: "Other" },
  ];
  const collegeOption = [
    {
      label: "Lucknow (IIIT, BBAU, LU)",
      value: "Lucknow (IIIT, BBAU, LU)",
    },
    // {
    //   label: "Dr.Shakuntala Misra National Rehabilitation University, Lucknow",
    //   value: "Dr.Shakuntala Misra National Rehabilitation University, Lucknow",
    // },
    /* {
      label: "Institute of Enginneering and Technology, Lucknow",
      value: "Institute of Enginneering and Technology, Lucknow",
    }, */
    { label: "NSIC, Hyderabad", value: "NSIC, Hyderabad" },
    {
      label: "SRR Government Arts & Science College, Telangana",
      value: "SRR Government Arts & Science College, Telangana",
    },
    { label: "NSIC, Delhi", value: "NSIC, Delhi" },
    {
      label: "Central Tool Room and Training Centre, Bhubaneshwar",
      value: "Central Tool Room and Training Centre, Bhubaneshwar",
    },
    // { label: "NSIC, Chennai", value: "NSIC, Chennai" },
    { label: "CSVTU, Chattisgarh", value: "CSVTU, Chattisgarh" },
    {
      label: "Sri. Jayachamarajendra (Government) Polytechnic, Bangalore",
      value: "Sri. Jayachamarajendra (Government) Polytechnic, Bangalore",
    },
    {
      label: "Dr. Lankapalli Bullayya College,Vizag",
      value: "Dr. Lankapalli Bullayya College,Vizag",
    },
  ];
  React.useEffect(() => {
    if (values.college) {
      let College;
      switch (values.college) {
        case "Lucknow (IIIT, BBAU, LU)":
          setCourseOptions([
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
            { label: "IoT", value: "0012y00000UY35tAAD" },
            { label: "C and P", value: "0012y00000UY35VAAT" },
          ]);
          break;
        case "Dr. Lankapalli Bullayya College":
          setCourseOptions([
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "Dr.Shakuntala Misra National Rehabilitation University, Lucknow":
          setCourseOptions([
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },

            { label: "C and P", value: "0012y00000UY35VAAT" },
          ]);
          break;
        case "Institute of Enginneering and Technology, Lucknow":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "NSIC, Hyderabad":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "SRR Government Arts & Science College, Telangana":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "NSIC, Delhi":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "IoT", value: "0012y00000UY35tAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "Central Tool Room and Training Centre, Bhubaneshwar":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "IoT", value: "0012y00000UY35tAAD" },
            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "NSIC, Chennai":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },

            { label: "IoT", value: "0012y00000UY35tAAD" },
          ]);
          break;
        case "CSVTU, Chattisgarh":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },

            { label: "Big Data", value: "0012y00000UY35UAAT" },
          ]);
          break;
        case "Sri. Jayachamarajendra (Government) Polytechnic, Bangalore":
          setCourseOptions([
            { label: "C and P", value: "0012y00000UY35VAAT" },
            { label: "AI", value: "0012y00000UY32vAAD" },
            { label: "IoT", value: "0012y00000UY35tAAD" },
          ]);
          break;
      }
    }
  }, [values.college]);

  const getStates = (value) => {
    const statesObject = CountryStateJson.countries.find(
      (country) => country.country === value
    );
    let statesArray = statesObject?.states || [];
    let statesOfValue = [
      {
        label: "State",
        value: "State",
      },
    ];
    statesArray.map((state) => {
      statesOfValue.push({
        label: state,
        value: state,
      });
    });
    return statesOfValue;
  };
  React.useEffect(() => {
    if (otp) {
      alert(`Please note down the OTP for further process. The OTP is ${otp}`);
    }
  }, [otp]);
  // useEffect(() => {
  //   getYear().then((res) => {
  //     let yearRes = [];
  //     res.map((el) => {
  //       yearRes.push({ label: el.year, value: el.year });
  //     });
  //     setYear(yearRes);
  //   });

  //   getDepartment().then((res) => {
  //     let departmentRes = [];
  //     console.log(res);
  //     res.map((el) => {
  //       departmentRes.push({ label: el.Name, value: el.Id });
  //     });
  //     setDepartment(departmentRes);
  //   });
  // }, []);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const handleSnackbarOpen = (severity, message) => {
    setOpenSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };
  values.handleSnackbarOpen = (severity, message) => {
    setOpenSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  values.handleLogin = () => {
    submitForm();
  };
  const handleOtp = async () => {
    // There are 2 onscreens change both
    const otpto = "email";
    if (otpto === "email") {
      sendOtpTo(values.email, mobile, otpto)
        .then((res) => {
          // if (res.success) {
          handleSnackbarOpen("success", t("OTP sent"));
          setShowOtpEnter(true);
          // } else {
          //   handleSnackbarOpen("error", t("Unable to send OTP."));
          // }
        })
        .catch((err) => {
          handleSnackbarOpen("error", t("OTP Error"));
        });
    } else {
      if (mobile.length === 10) {
        const registeredUser = false;
        if (!registeredUser) {
          console.log("registeredUser", registeredUser);
          if (otpto === GLOBAL.OTP.ON_SCREEN) {
            setShowOtpEnter(true);
            setOtp(otpGenerator());
          } else {
            sendOtpTo(values.email, mobile, otpto)
              .then((res) => {
                if (res.status === GLOBAL.RESPONSE_STATUS.SUCESS) {
                  handleSnackbarOpen("success", t("OTP sent"));
                  setShowOtpEnter(true);
                } else {
                  handleSnackbarOpen("error", t("Unable to send OTP."));
                }
              })
              .catch((err) => {
                handleSnackbarOpen("error", t("OTP Error"));
              });
          }
        } else {
          handleSnackbarOpen("error", t("Please check your Mobile Number"));
        }
      } else {
        handleSnackbarOpen("error", t("Please check your Mobile Number"));
      }
    }
  };
  const submitForm = () => {
    console.log("declaration", values.declaration);
    if (values.declaration) {
      setLoading(true);
      //lead
      let payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        Phone: `${values.countrycode}${values.mobile}`,
        College__c: values.college,
        Aadhar_Number__c: values.adharCardNumber,
        Age__c: values.age,
        Educational_Qualification__c: values.educationQualification,
        Please_state_your_Enquiry_Request_Course__c:
          values.pleaseStateYourRequest,
        Email: values.email,
        Applying_To__c: values.course,
        Company: "Samsung",
      };
      // let payload = {
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   Company: "Samsung",
      //   Status: "New",
      //   LeadSource: "Web",
      //   College__c: values.college,
      //   Phone: `${values.countrycode}${values.mobile}`,
      //   Applying_To__c: values.course,
      //   Aadhar_Number__c: values.adharCardNumber,
      //   Age__c: values.age,
      //   Educational_Qualification__c: values.educationQualification,
      //   Please_state_your_Enquiry_Request_Course__c:
      //     values.pleaseStateYourRequest,
      //   Email: values.email,
      //   Other__c: values.other,
      // };
      console.log("payload", payload);
      let payload2 = {
        firstName: values.firstName,
        lastName: values.lastName,
        Phone: `${values.countrycode}${values.mobile}`,
        College__c: values.college,
        Aadhar_Number__c: values.adharCardNumber,
        Age__c: values.age,
        Educational_Qualification__c: values.educationQualification,
        Please_state_your_Enquiry_Request_Course__c:
          values.pleaseStateYourRequest,
        Email: values.email,
        RecordTypeId: "0129D000000EqEcQAK",
        AccountId: "0019D00000P5aIJQAZ",
        Other__c: values.other,
      };
      sendOtp(values.email)
        .then((resOtp) => {
          if (resOtp?.success === true) {
            setLoading(false);
            // const otp = resOtp?.OTP;
            // alert(`Your OTP is: ${otp}`);
            handleSnackbarOpen("success", t("OTP sent"));

            props.setLeadData(payload);
            // props.setContactData(payload2);

            props.setEmailId(values.email);
            props.setShowForm(false);
          }
        })
        .catch((err) => {
          console.log("OTP send Error", err);
        });

      // createContact(payload2)
      //   .then((res) => {
      //     setLoading(true);
      //     if (res?.success === true) {
      //       sendOtp(values.email)
      //         .then((resOtp) => {
      //           if (resOtp?.success === true) {
      //             setLoading(false);
      //             const otp = resOtp?.OTP;
      //             alert(`Your OTP is: ${otp}`);
      //             handleSnackbarOpen("success", t("OTP sent"));

      //             props.setLeadData(payload);
      //             props.setContactData(payload2);

      //             props.setEmailId(values.email);
      //             props.setShowForm(false);
      //           }
      //         })
      //         .catch((err) => {
      //           console.log("OTP send Error", err);
      //         });
      //     }

      //     // props.setShowForm(false);
      //     // handleSnackbarOpen("success", "Registration Successful");
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //     setLoading(false);
      //     values.handleSnackbarOpen("warning", "Something went wrong.");
      //   });

      // sendOtp(values.email)
      //   .then((resOtp) => {
      //     if (resOtp?.success === true) {
      //       setLoading(false);
      //       handleSnackbarOpen("success", t("OTP sent"));
      //       props.setEmailId(values.email);
      //       props.setLeadData(payload);
      //       props.setContactData(payload2);
      //       props.setShowForm(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     console.log("OTP send Error", err);
      //   });

      /* postDetails(payload)
        .then((res) => {
          setLoading(true);
          if (res.status === "success") {
            createContact(payload2)
              .then((res) => {
                setLoading(true);
                if (res?.success === true) {
                  sendOtp(values.email)
                    .then((resOtp) => {
                      if (resOtp?.success === true) {
                        setLoading(false);
                        handleSnackbarOpen("success", t("OTP sent"));
                        props.setShowForm(false);
                        props.setEmailId(values.email);
                      }
                    })
                    .catch((err) => {
                      console.log("OTP send Error", err);
                    });
                }

                // props.setShowForm(false);
                // handleSnackbarOpen("success", "Registration Successful");
              })
              .catch((err) => {
                console.log("err", err);
                setLoading(false);
                values.handleSnackbarOpen("warning", "Something went wrong.");
              });
          } else {
            values.handleSnackbarOpen("warning", "Something went wrong.");
          }
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
          values.handleSnackbarOpen("warning", "Something went wrong.");
        }); */
    } else {
      setLoading(false);
      values.handleSnackbarOpen("error", "Please accept the declaration");
    }
  };
  return (
    <React.Fragment>
      <form style={{ position: "relative" }} className="au-form">
        {values.loading && <KenLoader size={24} />}
        <div style={{ margin: "0px 0px 0px 0px" }}>
          <Typography className={classes.headerTitle}>
            Information Form
          </Typography>
          <Grid container style={{ padding: "12px 24px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    onChange={change.bind(null, "firstName")}
                    name="firstName"
                    // name="studentName"
                    value={values.firstName}
                    placeholder="Enter First Name"
                    label="First Name"
                    errors={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    onChange={change.bind(null, "lastName")}
                    // name="firstName"
                    name="lastName"
                    value={values.lastName}
                    placeholder="Enter Last Name"
                    label="Last Name"
                    errors={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    onChange={change.bind(null, "age")}
                    // name="lastName"
                    name="age"
                    placeholder="Enter Age "
                    value={values.age}
                    label="Age"
                    errors={errors.age}
                    touched={touched.age}
                  />
                </div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <KenSelect
                  required={true}
                  onChange={change.bind(null, "educationQualification")}
                  // name='email'
                  name="educationQualification"
                  options={educationOption}
                  value={values.educationQualification}
                  label="Education Qualification"
                  placeholder="Enter Education Qualification"
                  errors={errors.educationQualification}
                  touched={touched.educationQualification}
                />
              </Grid>
              {values?.educationQualification === "Other" && (
                <Grid item sm={6} xs={12}>
                  <div className="form-field">
                    <KenInputField
                      required={false}
                      name="other"
                      onChange={change.bind(null, "other")}
                      value={values.other}
                      label="Please Mention if Other"
                      placeholder="Please Mention if Other"
                      errors={errors.other}
                      touched={touched.other}
                    />
                  </div>
                </Grid>
              )}
              <Grid item sm={6} xs={12}>
                <KenSelect
                  required={true}
                  onChange={change.bind(null, "college")}
                  // name='email'
                  name="college"
                  options={collegeOption}
                  value={values.college}
                  label="Training Centre"
                  placeholder="Enter Training Centre"
                  errors={errors.college}
                  touched={touched.college}
                />
              </Grid>
              {/* <Grid item sm={3} xs={12}>
                <div className="form-field-btn">
                  <div className={clsx({ [classes.hide]: showOtpEnter })}>
                    <KenButton
                      className={classes.submitBtn}
                      style={{ fontSize: 14, backgroundColor: "#192339" }}
                      variant="primary"
                      onClick={() => {
                        handleOtp();
                      }}
                    >
                      Get OTP
                    </KenButton>
                  </div>
                </div>
                <div className="form-field-btn">
                  <div className={clsx({ [classes.hide]: !showOtpEnter })}>
                    <KenButton
                      className={classes.submitBtn}
                      style={{ fontSize: 14, backgroundColor: "#192339" }}
                      variant="primary"
                      onClick={() => {
                        handleOtp();
                      }}
                    >
                      Resend OTP
                    </KenButton>
                  </div>
                </div>
              </Grid> */}
              {/* <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    onChange={change.bind(null, "otp")}
                    name="otp"
                    value={values.otp}
                    label="OTP"
                    placeholder="Enter OTP"
                    errors={errors.otp}
                    touched={touched.otp}
                  />
                </div>
              </Grid> */}
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={true}
                  label="Aadhar Number"
                  // name='mobile'
                  name="adharCardNumber"
                  placeholder="Enter Aadhar Number"
                  value={values.adharCardNumber}
                  onChange={change.bind(null, "adharCardNumber")}
                  startAdornment
                  errors={errors.adharCardNumber}
                  touched={touched.adharCardNumber}
                  // startAdornmentIcon={
                  //   <div style={{ marginTop: "-4px", marginBottom: "0px" }}>
                  //     <KenSelect
                  //       optionalLabel={false}
                  //       options={options}
                  //       value={values.countrycode}
                  //       name='countrycode'
                  //       onChange={change.bind(null, "countrycode")}
                  //       defaultValue={() => {
                  //         setFieldValue("countrycode", "91");
                  //         return "+91";
                  //       }}
                  //     />
                  //   </div>
                  // }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <KenSelect
                  required={true}
                  onChange={change.bind(null, "course")}
                  // name='designation'
                  name="course"
                  options={courseOptions}
                  value={values.course}
                  label="Course"
                  placeholder="Enter Course"
                  errors={errors.course}
                  touched={touched.course}
                />
              </Grid>

              {/* <Grid item sm={6} xs={12}>
                <KenInputField
                  required={true}
                  onChange={change.bind(null, "year")}
                  // name='startup'
                  name="year"
                  value={values.year}
                  label="Year"
                  placeholder="Enter Year"
                  errors={errors.year}
                  touched={touched.year}
                />
              </Grid> */}
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={true}
                  label="Mobile"
                  name="mobile"
                  placeholder="Enter Mobile Number"
                  value={values.mobile}
                  onChange={change.bind(null, "mobile")}
                  startAdornment
                  errors={errors.mobile}
                  touched={touched.mobile}
                  startAdornmentIcon={
                    <div style={{ marginTop: "-4px", marginBottom: "0px" }}>
                      <KenSelect
                        optionalLabel={false}
                        options={options}
                        value={values.countrycode}
                        name="countrycode"
                        onChange={change.bind(null, "countrycode")}
                        defaultValue={() => {
                          setFieldValue("countrycode", "91");
                          return "+91";
                        }}
                      />
                    </div>
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={true}
                  onChange={change.bind(null, "email")}
                  name="email"
                  value={values.email}
                  label="Email"
                  placeholder="Enter Email"
                  errors={errors.email}
                  touched={touched.email}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={false}
                  onChange={change.bind(null, "pleaseStateYourRequest")}
                  // name='email'
                  name="pleaseStateYourRequest"
                  value={values.pleaseStateYourRequest}
                  label="Please State Your Enquiry"
                  placeholder="Enter Enquiry"
                  // errors={errors.pleaseStateYourRequest}
                  // touched={touched.pleaseStateYourRequest}
                />
              </Grid>
              {/* <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <KenInputField
                  required={false}
                  onChange={change.bind(null, "dob")}
                  name="dob"
                  value={props.values.dob}
                  type="date"
                  placeholder="mm/dd/yy"
                  label="Date Of Birth"
                  errors={errors.dob}
                  touched={touched.dob}
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <KenSelect
                  required={true}
                  name='country'
                  value={values.country}
                  label='Country'
                  placeholder='Select'
                  options={CountryJson.countries}
                  defaultValue={() => {
                    setFieldValue("country", "India");
                    return "India";
                  }}
                  errors={errors.country}
                  touched={touched.country}
                  onChange={change.bind(null, "country")}
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <KenSelect
                  required={false}
                  name='state'
                  value={values.state}
                  label='State'
                  placeholder='Select'
                  // options={stateCity.states}
                  options={getStates(values.country)}
                  errors={errors.state}
                  touched={touched.state}
                  onChange={change.bind(null, "state")}
                />
              </Grid>
                {values?.country?.toUpperCase() === "INDIA" ? ( <>
                  <Grid item sm={6} xs={12} className={classes.selectGrid}>
                  <KenSelect
                    required={false}
                    name='city'
                    value={values.city}
                    label='City/District'
                    options={getCities(values.state)}
                    errors={errors.city}
                    touched={touched.city}
                    onChange={change.bind(null, "city")}
                  />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.selectGrid}>
                  <KenInputField
                    required={false}
                    name='tehsil'
                    value={values.tehsil}
                    label='Tehsil/Taluk'
                    errors={errors.tehsil}
                    touched={touched.tehsil}
                    onChange={change.bind(null, "tehsil")}
                  />
                  </Grid>
                  </>
                ) : (
                  <Grid item sm={6} xs={12} className={classes.selectGrid}>
                  <KenInputField
                    required={false}
                    name='city'
                    value={values.city}
                    label='City/District'
                    errors={errors.city}
                    touched={touched.city}
                    onChange={change.bind(null, "city")}
                  />
                  </Grid>
                )}
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="qualifications"
                    value={values.qualifications}
                    label="Qualifications"
                    placeholder="Select"
                    options={qualifications}
                    errors={errors.qualifications}
                    touched={touched.qualifications}
                    onChange={change.bind(null, "qualifications")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="courseList"
                    value={values.courseList}
                    label="Applying to"
                    placeholder="Select"
                    options={courses}
                    errors={errors.courseList}
                    touched={touched.courseList}
                    onChange={change.bind(null, "courseList")}
                  />
                </div>
              </Grid>
             
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="referralList"
                    value={values.referralList}
                    label="Referral Details"
                    placeholder="Select"
                    options={referrals}
                    errors={errors.referralList}
                    touched={touched.referralList}
                    onChange={change.bind(null, "referralList")}
                  />
                </div>
              </Grid>
             { (values.referralList == "Friends" || values.referralList == "Relatives") && (
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    onChange={change.bind(null, "referralName")}
                    name="referralName"
                    placeholder="Enter Referral Name"
                    value={values.referralName}
                    label="Referral Name"
                    errors={errors.referralName}
                    touched={touched.referralName}
                  />
                </div>
              </Grid>)
              }

              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="mobiliser"
                    value={values.mobiliser}
                    label="Mobiliser Details"
                    placeholder="Select"
                    options={mobiliser}
                    errors={errors.mobiliser}
                    touched={touched.mobiliser}
                    onChange={change.bind(null, "mobiliser")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={false}
                    name="caste"
                    value={values.caste}
                    label="Caste"
                    placeholder="Select"
                    options={caste}
                    errors={errors.caste}
                    touched={touched.caste}
                    onChange={change.bind(null, "caste")}
                  />
                </div>
              </Grid> */}

              <Grid item xs={12} style={{ display: "flex" }}>
                <div className="form-checkbox">
                  <KenCheckbox
                    name="declaration"
                    value={values.declaration}
                    onChange={(event) => {
                      setFieldValue("declaration", event.target.checked);
                    }}
                  ></KenCheckbox>
                </div>
                <Typography className={classes.declarationText}>
                  I agree to receive information from Samsung Innovation Campus
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <KenButton
            className={classes.submitBtn}
            style={{ fontSize: 14, backgroundColor: "#192339" }}
            variant="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </KenButton>
        </div>
      </form>
      <KenSnackBar
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={5000}
        open={openSnackbar}
        handleSnackbarClose={handleSnackbarClose}
        position="Bottom-Right"
      />
    </React.Fragment>
  );
};

const FormikHoc = (props) => {
  let firstName;
  let middleName;
  let lastName;
  let email;
  let mobile;
  let country;
  let countrycode;
  let state;
  let city;
  let handleSnackbarOpen;
  let referralList;
  let referralName;
  let courseList;
  let mobiliser;
  let caste;
  let qualifications;
  let tehsil;
  let designation;
  let startup;
  let studentName;
  let age;
  let educationQualification;
  let college;
  let adharCardNumber;
  let course;
  let year;
  let other;
  let pleaseStateYourRequest;

  const [toggle, setToggle] = useState(true);
  const stringRegExp = /^[a-zA-Z ]*$/;
  const numberRegExp = /^[1-9]?[0-9]{1}$|^100$/;
  const yearRegExp = /^[0-9]{4,4}$/;
  const adharRegExp = /^[0-9]{12,12}$/;

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const pincodeExp = /^[0-9]{6,6}$/;
  const fullNameExp = /^(\w+\s+\w+)$/;
  const [program, setProgram] = useState();
  const [academicPrograms, setacademicPrograms] = useState("EPGDM");
  let [loading, setLoading] = useState(false);
  const [prog, setProg] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);

  const FormikForm = withFormik({
    mapPropsToValues: () => ({
      firstName: firstName,
      lastName: lastName,
      email: email,
      countrycode: countrycode,
      mobile: mobile,
      country: country,
      state: state,
      city: city,
      courseList: courseList,
      referralList: referralList,
      referralName: referralName,
      mobiliser: mobiliser,
      middleName: middleName,
      caste: caste,
      qualifications: qualifications,
      tehsil: tehsil,
      designation: designation,
      startup: startup,
      studentName: studentName,
      age: age,
      educationQualification: educationQualification,
      college: college,
      adharCardNumber: adharCardNumber,
      course: course,
      year: year,
      other: other,
      pleaseStateYourRequest: pleaseStateYourRequest,
      handleSnackbarOpen: handleSnackbarOpen,
      declaration: false,
      loading: loading,
      toggle: toggle,
      setToggle: setToggle,
    }),

    validationSchema: () =>
      Yup.object().shape({
        firstName: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(15, messages?.name?.max)
          .required(messages?.required),

        lastName: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(15, messages?.name?.max)
          .required(messages?.required),

        educationQualification: Yup.string().required(messages?.required),
        college: Yup.string().required(messages?.required),

        course: Yup.string().required(messages?.required),

        // pleaseStateYourRequest: Yup.string()
        // .required(messages?.required),

        age: Yup.number(messages?.age?.match)
          .min(18, messages?.age?.min)
          .max(25, messages?.age?.max)
          .required(messages?.required),

        // year: Yup.string()
        //   .matches(yearRegExp, messages?.year?.match)
        //   .max(4, messages?.year?.max)
        //   .required(messages?.required),
        // other: Yup.string().required(messages?.required),
        adharCardNumber: Yup.string()
          .matches(adharRegExp, messages?.adhar?.match)
          .max(12, messages?.adhar?.max)
          .required(messages?.required),

        // age: Yup.string().nullable().required(messages?.required),
        // adharCardNumber: Yup.string().nullable().required(messages?.required),
        // year: Yup.string().nullable().required(messages?.required),

        // middleName: Yup.string()
        // .matches(stringRegExp, messages?.name?.match)
        // .max(15, messages?.name?.max)
        // .required(messages?.required),
        // lastName: Yup.string()
        //   .matches(stringRegExp, messages?.name?.match)
        //   .max(15, messages?.name?.max)
        //   .required(messages?.required),
        email: Yup.string().email(messages?.email).required(messages?.required),
        mobile: Yup.string()
          .matches(phoneRegExp, messages?.mobile)
          .required(messages?.required),
        // dob: Yup.string().nullable().required(messages?.required),
        // country: Yup.string().nullable().required(messages?.required),
        // state:Yup.string().nullable().required(messages?.required),
        // courseList:Yup.string().nullable().required(messages?.required),
        // referralList:Yup.string().nullable().required(messages?.required),
        // mobiliser:Yup.string().nullable().required(messages?.required),
      }),
    handleSubmit: (values, { resetForm }) => {
      values.handleLogin();
    },
    displayName: "InfoForm",
  })(InfoForm);

  return (
    <FormikForm
      {...props}
      prog={prog}
      academicPrograms={academicPrograms}
      setProg={setProg}
      setLoading={setLoading}
    />
  );
};
